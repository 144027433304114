<template>
    <b-sidebar
      id="stock-form-sidebar"
      :visible="isStockFormSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="getData(MedicineData)"
     
      @change="(val) => $emit('update:is-stock-form-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{headertext}}
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
        <div class="m-2 form-boder-input">
            จำนวนคงเหลือ  {{ MedicineData.balance  }} {{ MedicineData.unit_name  }} 
     
            <b-tabs>
                 <b-tab title="เพิ่ม">
                    <b-form-group
                        label="จำนวน"
                        label-for="qty"
                    
              >
                        <b-form-input v-model="Data.stock_in" />

                    </b-form-group>
                    <div class="d-flex mt-2">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-2"
                        @click="addSubmit"
                        type="button"
                    >
                        เพิ่ม
                    </b-button>
                   
                    </div>
                   
                 </b-tab>
                <b-tab title="ลด">
                    <b-form-group
                        label="จำนวน"
                        label-for="qty"
                    
              >
                        <b-form-input v-model="Data.stock_out" />
                    </b-form-group>
                    <div class="d-flex mt-2">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-2"
                        type="button"
                        @click="minusSubmit"
                    >
                        ลด
                    </b-button>
                   
                    </div>
                </b-tab>
            </b-tabs>

        </div>
      
        <!-- BODY -->
        
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormCheckbox, BFormInput, BFormInvalidFeedback, BButton,BFormSelect,BFormTextarea,BTabs,BTab,BFormSpinbutton
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref,onUnmounted } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import useMedicineList from './useMedicineList'
  import { useToast } from 'vue-toastification/composition'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { slugify } from '@/@fake-db/utils'
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      BFormCheckbox,
      vSelect,
      BFormSelect,
      BFormTextarea,
      BTabs,
      BTab,
      BFormSpinbutton,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isStockFormSidebarActive',
      event: 'update:is-stock-form-sidebar-active',
    },
    props: {
      isStockFormSidebarActive: {
        type: Boolean,
        required: true,
      },
     
      MedicineData: {
        type: Object,
        required: true,
      },
      headertext:{
        type:String,
        required: true,
      }
  
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
      }
    },
   
    setup(props, { emit }) {
      const toast = useToast();
      const userStorage = JSON.parse(localStorage.getItem('userData'));
      console.log(userStorage);
      const PATIENTS_STORE_MODULE_NAME = 'app-medicine';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, medicineStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
    
        const {
            type_Data,
            group_Data,
            category_Data,
            unit_Data
        } = useMedicineList();
        var dates = new Date(new Date().setDate(new Date().getDate() + 730));
        var year  = dates.getFullYear();
  var month = (dates.getMonth() + 1).toString().padStart(2, "0");
  var day   = dates.getDate().toString().padStart(2, "0");
        let exp_date = year+'-'+month+'-'+day
       
      const blankData = {
        product_id:'',
        warehouse_id:0,
        branch_id:userStorage.branch_id,
        stock_id:0,
        stock_in:0,
        stock_out:0,
        price:0,
        exp_date:exp_date,
        is_active:1,
        created_by:userStorage.id
     
      }
  
      const Data = ref(JSON.parse(JSON.stringify(blankData)))
      const resetData = () => {
        Data.value = JSON.parse(JSON.stringify(blankData))
      }
      const getData = (data)=>{
        Data.value.product_id = data.id
        Data.value.stock_in = 0
        Data.value.stock_out = 0
       
      }
      const addSubmit = async() => {
       let response = await store.dispatch('app-medicine/addStockData', Data.value);
       if(response.data.success){
        toast({
              component: ToastificationContent,
              props: {
                title: "Add Success",
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })

       }else{
        toast({
              component: ToastificationContent,
              props: {
                title: "Add Error",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })

       }
       emit('refetch-data')
              emit('update:is-stock-form-sidebar-active', false)
      }
      const minusSubmit = async() => {
        let response = await store.dispatch('app-medicine/removeStockData', Data.value);
       if(response.data.success){
        toast({
              component: ToastificationContent,
              props: {
                title: "Add Success",
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })

       }else{
        toast({
              component: ToastificationContent,
              props: {
                title: "Add Error",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })

       }
       emit('refetch-data')
              emit('update:is-stock-form-sidebar-active', false)
      }
      const onSubmit = () => {
        //default 
        Data.value.branch_id = userStorage.branch_id;
        Data.value.created_by = userStorage.id;
        //
        console.log('update medicine',Data.value);
        if(Data.value.is_active == true){
          Data.value.is_active = 1;
          
        }
        else if(Data.value.is_active == false){
          Data.value.is_active = 0;
        }
         /* if(Data.value.id){
            console.log(Data.value);
            store.dispatch('app-medicine/updateData', Data.value)
            .then(() => {
              emit('refetch-data')
              emit('update:is-stock-form-sidebar-active', false)
              toast({
              component: ToastificationContent,
              props: {
                title: "Save Success",
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            }).catch(()=>{
            toast({
              component: ToastificationContent,
              props: {
                title: "Error Save",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
      
          })
  
        }
        else{
            store.dispatch('app-medicine/addData', Data.value)
            .then(() => {
              emit('refetch-data')
              emit('update:is-stock-form-sidebar-active', false)
              toast({
              component: ToastificationContent,
              props: {
                title: "Save Success",
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            }).catch(()=>{
            toast({
              component: ToastificationContent,
              props: {
                title: "Error Save",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
      
          })
  
          }   */
      }
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation(resetData)
  
  
      return {
        Data,
        onSubmit,
  
        refFormObserver,
        getValidationState,
        resetForm,
        getData,
        type_Data,
        group_Data,
        category_Data,
        unit_Data,
        addSubmit,
        minusSubmit
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  
  #stock-form-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  