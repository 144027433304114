import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useMedicineList() {
  const type_Data = ref([]);
  const category_Data = ref([]);
  const group_Data = ref([]);
  const warehouse_Data = ref([]);
  const unit_Data = ref([]);

  const searchQuery = ref('');
  const selected_group = ref(0);
  const selected_category = ref(0);
  const selected_type = ref(0);
  const selected_status = ref(0);
  const refListTable = ref(null);
  const currentPage = ref(1);
  const pageLength = ref(10);
  const Total = ref(0);
  const dir = ref(false);
  const toast = useToast()
  const tableColumns = [

      { field: 'id', label: 'id', sortable: false, hidden:true },
      { field: 'sku', label: 'รหัสสินค้า', sortable: false },
        { field: 'title', label: 'ชื่อ', sortable: false },

        { field: 'group_name', label: 'กลุ่ม', sortable: false },
        { field: 'category_name', label: 'หมวดหมู่', sortable: false },
        { field: 'type_name', label: 'ประเภท', sortable: false },
        
        { field: 'sell_price', label: 'ราคาขาย', sortable: false },
        { field: 'balance', label: 'คงเหลือ', sortable: false },
        { field: 'unit_name', label: 'หน่วย', sortable: false },
        { field: 'is_active', label: 'สถานะ', sortable: false },
        { field: 'action', label: 'การจัดการ', sortable: false },


    ]
    const Data = ref([]);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    console.log('branch_id',branch_id);
    const refetchData = () => {
      refListTable.value.refresh()
  }
    watch([currentPage, pageLength, searchQuery,selected_group,selected_category,selected_type,selected_status], () => {
      fetchData();
    })

    const fetchData = async () => {
        const offset = pageLength.value*(currentPage.value-1);
        Data.value  = [];
        await store
          .dispatch('app-medicine/fetchData', {
            limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            group_id:selected_group.value,
            category_id:selected_category.value,
            type_id:selected_type.value,
            is_active:selected_status.value,
            branch_id:branch_id,
            order_by:'medicines.id DESC'
         
          })
          .then(response => {
          
            let data = response.data.data;
            response.data.data.forEach((data) => data.balance = data.stock_in - data.stock_out);

            Data.value = response.data.data;

            console.log('medicines medicines value',Data.value);
            const total = response.data.total?response.data.total: Data.value.length;
           
            Total.value = total
          })
          .catch((error) => {
            console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching medicines' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }

    const fetchGroupData  = async () => { 
      group_Data.value  = [];
      await store.dispatch('app-medicine/fetchGroupData',{
        is_active:1,
      })
      .then(response => {
      group_Data.value  = response.data.data
      })
      .catch((error) => {
      console.log('error',error);
      })
  }

  const fetchCategoryData  = async () => { 
    category_Data.value  = [];
    await store.dispatch('app-medicine/fetchCategoryData',{
        is_active:1,
       //branch_id:branch_id,
      })
      .then(response => {

      response.data.data.forEach((data) => {
      
        if(data.group_id == 1){category_Data.value.push(data)}
        else if(data.group_id == 2){type_Data.value.push(data)}
      });
      
      })
      .catch((error) => {
      console.log('error',error);
      })
  }

  const fetchWarehouseData  = async () => { 
    warehouse_Data .value  = [];
    await store.dispatch('app-medicine/fetchWarehouseData',{
      is_active:1,
      //branch_id:branch_id,
    })
    .then(response => {

    warehouse_Data.value  = response.data.data
    })
    .catch((error) => {
    console.log('error',error);
    })
  }

  const fetchUnitData  = async () => { 
    type_Data.value  = [];
    await store.dispatch('app-medicine/fetchUnitData',{
      is_active:1,
      //branch_id:branch_id,
    })
    .then(response => {

    unit_Data.value  = response.data.data
    })
    .catch((error) => {
    console.log('error',error);
    })
  }
  fetchUnitData();
  fetchGroupData();
  fetchCategoryData();
  fetchData();
  const deleteData = (id) => {
    //fetchDeleteProperty
    var data = {id:id}
    
    var res = store.dispatch('app-medicine/deleteData',data)
    res.then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      fetchData();
    }).catch(()=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Delete",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
    //console.log('id', id);
    
  }
  const resolveUserStatusVariant = status => {
       
    if (status) return 'success'
    if (!status) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusnameVariant = status => {
  
      if (status) return 'Active'
      if (!status) return 'Inactive'
      return 'primary'
  }
  const resolveMedicineVariant = status =>{
    if (status == 'ยา') return 'danger'
    else if (status == 'อุปกรณ์') return 'primary'
    return 'light'
  }
  const resolveMedicineGroupnameVariant = status =>{
    console.log('status',status);
    if (status == 'ยา') return 'HeartIcon'
    else if (status == 'อุปกรณ์') return 'SquareIcon'
    else return status;
    return 'light'
  }
    return {
        fetchData,
        tableColumns,
        type_Data,
        fetchCategoryData,
        group_Data,
        fetchGroupData,
        category_Data,
        fetchWarehouseData,
        warehouse_Data,
        fetchUnitData,
        unit_Data,
        deleteData,
        Total,
        Data,
        pageLength,
        dir,
        searchQuery,
        selected_group,
        selected_category,
        selected_type,
        selected_status,
        currentPage,
        refListTable,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant,
        resolveMedicineVariant,
        resolveMedicineGroupnameVariant
    }
}