<template>
    <b-sidebar
      id="medicine-form-sidebar"
      :visible="isMedicineFormSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="getData(MedicineData)"
      @hidden="resetForm"
      @change="(val) => $emit('update:is-medicine-form-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{headertext}}
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
  
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2 form-boder-input"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
          <validation-provider
              #default="validationContext"
              name="group"
            >
              <b-form-group
                label="กลุ่ม"
                label-for="group"
                rule="required"
              >
                <v-select
                  :options="group_Data"
                  v-model="Data.group_id"
                  :get-option-label="(option) => option.title"
                  :reduce="val => val.id"
                  >
                  <template #option="{ title }">
                  {{ title }}
                  <br />
                  </template>
                </v-select>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="code"
              rules="required"
            >
              <b-form-group
                label="รหัสสินค้า"
                label-for="code"
              >
                <b-form-input
                  id="code"
                  v-model="Data.code"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="รหัสสินค้า"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="title"
              rules="required"
            >
              <b-form-group
                label="ชื่อสินค้า"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="Data.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="ชื่อสินค้า"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            
            <validation-provider
              #default="validationContext"
              name="sell_price"
              rules="required"
            >
              <b-form-group
                label="ราคาขาย"
                label-for="sell_price"
              >
                <b-form-input
                  id="sell_price"
                  v-model="Data.sell_price"
                  type="number"
                  step="0.01"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="ราคาขาย"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="how_to_use"
              rules="min:0"
            >
              <b-form-group
                label="วิธีใช้"
                label-for="how_to_use"
              >
                <b-form-textarea
                  id="how_to_use"
                  v-model="Data.how_to_use"
                  
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="วิธีใช้"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="descripiton"
              rules="min:0"
            >
              <b-form-group
                label="รายละเอียด"
                label-for="descripiton"
              >
                <b-form-textarea
                  id="descripiton"
                  v-model="Data.descripiton"
                  
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="รายละเอียด"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            
            <validation-provider
              #default="validationContext"
              name="unit"
              rules="required"
            >
              <b-form-group
                label="หน่วย"
                label-for="unit"
              >
                  
                <v-select
                  :options="unit_Data"
                  v-model="Data.unit_id"
                  :get-option-label="(option) => option.unit"
                  :reduce="val => val.id"
                  >
                  <template #option="{ unit,sub_unit,num }">
                  {{ unit }}
                  <!--<br />
                  <cite v-if="sub_unit">{{num}} {{ sub_unit }} ต่อ 1 {{ unit }}</cite>-->
                  </template>
                </v-select>
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="category"
            >
              <b-form-group
                label="หมวดหมู่"
                label-for="category"
              >
                <v-select
                  :options="category_Data"
                  v-model="Data.category_id"
                  :get-option-label="(option) => option.title"
                  :reduce="val => val.id"
                  >
                  <template #option="{ title }">
                  {{ title }}
                  <br />
                  </template>
                </v-select>
               
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="type"
            >
              <b-form-group
                label="ประเภท"
                label-for="type"
              >
                <v-select
                  :options="type_Data"
                  v-model="Data.type_id"
                  :get-option-label="(option) => option.title"
                  :reduce="val => val.id"
                  >
                  <template #option="{ title }">
                  {{ title }}
                  <br />
                  </template>
                </v-select>
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          
            
            <!-- Username -->
            <validation-provider
              #default="validationContext"
              name="status"
            >
              <b-form-group
                label="status"
                label-for="status"
                class="mt-2"
              >
                <b-form-checkbox 
                  id="status"
                  v-model="Data.is_active"
                  :state="getValidationState(validationContext)"
                  trim name="check-button" 
                  switch
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  
            
          
  
         
          
  
  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
  
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormCheckbox, BFormInput, BFormInvalidFeedback, BButton,BFormSelect,BFormTextarea
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref,onUnmounted } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import useMedicineList from './useMedicineList'
  import { useToast } from 'vue-toastification/composition'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { slugify } from '@/@fake-db/utils'
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      BFormCheckbox,
      vSelect,
      BFormSelect,
      BFormTextarea,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isMedicineFormSidebarActive',
      event: 'update:is-medicine-form-sidebar-active',
    },
    props: {
      isMedicineFormSidebarActive: {
        type: Boolean,
        required: true,
      },
     
      MedicineData: {
        type: Object,
        required: true,
      },
      headertext:{
        type:String,
        required: true,
      }
  
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
      }
    },
   
    setup(props, { emit }) {
      const toast = useToast();
      const userStorage = JSON.parse(localStorage.getItem('userData'));
      console.log(userStorage);
      const PATIENTS_STORE_MODULE_NAME = 'app-medicine';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, medicineStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const {
            type_Data,
            group_Data,
            category_Data,
            unit_Data
        } = useMedicineList();
      const blankData = {
        code:null,
        unit: '',
        sub_unit: '',
        num: null,
        group_id: null,
        category_id: null,
        type_id: null,
        branch_id:userStorage.branch_id,
        how_to_use:null,
        description:null,
        is_active:true,
        created_by: null,
     
      }
  
      const Data = ref(JSON.parse(JSON.stringify(blankData)))
      const resetData = () => {
        Data.value = JSON.parse(JSON.stringify(blankData))
      }
      const getData = (data)=>{
        if(data.id){ 
          Data.value  = JSON.parse(JSON.stringify(data))
        }
      }
      
      const onSubmit = () => {
        //default 
        Data.value.branch_id = userStorage.branch_id;
        Data.value.created_by = userStorage.id;
        //
        console.log('update medicine',Data.value);
        if(Data.value.is_active == true){
          Data.value.is_active = 1;
        }
        else if(Data.value.is_active == false){
          Data.value.is_active = 0;
        }
          if(Data.value.id){
            console.log(Data.value);
            store.dispatch('app-medicine/updateData', Data.value)
            .then(() => {
              emit('refetch-data')
              emit('update:is-medicine-form-sidebar-active', false)
              toast({
              component: ToastificationContent,
              props: {
                title: "Save Success",
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            }).catch(()=>{
            toast({
              component: ToastificationContent,
              props: {
                title: "Error Save",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
      
          })
  
        }
        else{
            store.dispatch('app-medicine/addData', Data.value)
            .then(() => {
              emit('refetch-data')
              emit('update:is-medicine-form-sidebar-active', false)
              toast({
              component: ToastificationContent,
              props: {
                title: "Save Success",
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            }).catch(()=>{
            toast({
              component: ToastificationContent,
              props: {
                title: "Error Save",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
      
          })
  
          }   
      }
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation(resetData)
  
  
      return {
        Data,
        onSubmit,
  
        refFormObserver,
        getValidationState,
        resetForm,
        getData,
        type_Data,
        group_Data,
        category_Data,
        unit_Data,
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  
  #medicine-form-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  